/* async:start */
footer {
  width: 100%;
}
.footerContainer {
  width: 100%;
  text-align: center;
}
.footerSocialContainer {
  padding: 60px 0;
  background: #333333;
}
.footerLinksContainer {
  padding: 40px 20px;
  background: #333333;
}
.footerLinksContainer .footerLinks {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.footerLinksContainer .footerLinks .footerLinkSet {
  margin-bottom: 40px;
}
.footerLinksContainer .footerLinks .footerLinkSet .footerHeading {
  font-family: 'Montserrat', sans-serif;
  color: white;
  margin-bottom: 10px;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.25rem;
  line-height: 1.375rem;
}
.footerLinksContainer .footerLinks .footerLinkSet .footerLinkList {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: center;
      justify-content: center;
}
.footerLinksContainer .footerLinks .footerLinkSet .footerLinkList .footerLinkItem {
  padding: 0 7px;
}
.footerLinksContainer .footerLinks .footerLinkSet .footerLinkList .footerLinkItem .footerLink {
  color: #333333;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 1.125rem;
  font-size: 12px;
  color: #666666;
  font-family: 'Montserrat', sans-serif;
  color: white;
  line-height: 2;
  display: inline-block;
  padding: 4px;
  text-decoration: underline;
}
.footerLinksContainer .footerLinks .footerLinkSet .footerLinkList .footerLinkItem .footerLink.privacyChoices .footerLinkImage {
  height: 14px;
  margin-left: 4px;
  margin-bottom: -2px;
  display: inline-block;
}
.footerLinksContainer .footerLinks .footerLinkSet .footerLinkList .footerLinkItem .footerLink:hover,
.footerLinksContainer .footerLinks .footerLinkSet .footerLinkList .footerLinkItem .footerLink:focus {
  text-decoration: none;
}
.footerLinksContainer .footerLinks .truste {
  margin-top: 5px;
}
.footerLinksContainer .footerLinks .copyright {
  font-family: 'Montserrat', sans-serif;
  color: white;
}
.footerLinksContainer .footerLinks .bottomLinks {
  padding-top: 40px;
}
/* async:end */
