@media screen and (min-width:1000px) {
/* async:start */
.footerLinksContainer {
  padding: 40px 20px 80px;
}
.footerLinksContainer .footerLinks {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.footerLinksContainer .footerLinks .footerLinkSet {
  -ms-flex: 1 1 20%;
      flex: 1 1 20%;
  text-align: left;
  margin-bottom: 0;
}
.footerLinksContainer .footerLinks .footerLinkSet .footerLinkList {
  display: block;
}
.footerLinksContainer .footerLinks .footerLinkSet .footerLinkList .footerLinkItem {
  padding: 0;
}
.footerLinksContainer .footerLinks .footerLinkSet .footerLinkList .footerLinkItem .footerLink {
  line-height: 3;
  padding: 0;
}
.footerLinksContainer .footerLinks .copyright {
  -ms-flex: 1 1 40%;
      flex: 1 1 40%;
  text-align: right;
}
.footerLinksContainer .footerLinks .bottomLinks .footerLinkList {
  display: -ms-flexbox;
  display: flex;
}
.footerLinksContainer .footerLinks .bottomLinks .footerLinkList .footerLinkItem {
  padding: 0 7px;
}
/* async:end */

}
